<template>
	<section :id="id" class="partners-section">
		<h1>In partnership with</h1>
		<ul class="partners" :class="{ mobile: 'partnersMobile' }">
			<li v-for="p in partners" :key="p.id">
				<a :href="p.link">
					<img :src="partnerPhotoImgSource(p.photo)" :alt="p.name"/>
				</a>
			</li>
		</ul>
	</section>
</template>

<script>

export default {
	name: 'PartnersSection',
	components: {},
	props: {
		id: {
			type: String,
			default: null,
		},
		partners: {
			type: Array,
			default: () => [],
		}
	},
	data() {
		return {
			mobile: false,
		}
	},
	created() {
		window.addEventListener('resize', this.checkMobile)
		this.checkMobile()
	},
	mounted() {},
	computed: {},
	methods: {
		partnerPhotoImgSource(photo) {
			return require(`@/assets/${this.$options.name}/${photo}`)
		},
		checkMobile() {
			this.mobile = window.innerWidth <= 900
		},
	},
}
</script>

<style lang="scss" scoped>
// COLORS
$accent-color: #0396FF;
$bg-color: #cdcdcd;
$dark-text-color: rgb(60, 60, 60);
// FONTS
$paragraph-title-font: "Abril Fatface", cursive;
$paragraph-content-font: "Advent Pro", sans-serif;
.partners-section {
	min-width: 320px;
	padding: 0px 0px 50px 0px;
	display: flex;
	flex-direction: column;;
	align-items: center;
	justify-content: center;

	h1 {
		width: 33%;
		min-width: 320px;
		max-width: 400px;
		margin: 50px 0px;
		text-align: center;
		font-size: 2rem;
		font-weight: 400;
		color: $dark-text-color;
		font-family: $paragraph-title-font;
	}
	
	.partners {
		list-style: none;
		max-width: 900px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 2px;
		
		li {
			border-radius: 2px;
			max-width: 150px;
			max-height: 150px;
			overflow: hidden;
			
			img {
				width: 100%;
			}
		}
	}

	.partnersMobile {
		flex-direction: column;
	}
}
</style>