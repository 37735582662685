<template>
	<section :id="id">
	</section>
</template>

<script>
export default {
	name: "FooterSection",
	props: {
		id: {
			type: String,
			default: null,
		},
	},
}
</script>

<style lang="scss" scoped>
section {
	width: 100%;
	height: 500px;
	background: black;
}
</style>