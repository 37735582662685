<template>
	<section :id="id" class="home-section">
		<article :class="{ 'mobileArticle': mobile }">
			<div class="text" :class="{ 'mobileText': mobile }">
				<h2>Excepteur ut ut ullamco incididunt occaecat.</h2>
				<p>Velit ad in dolor ad esse tempor cillum dolore pariatur excepteur qui occaecat do. Voluptate reprehenderit dolor commodo aliquip in amet. Occaecat deserunt laborum excepteur quis.</p>
				<a :href="`mailto:${tryEmail.email}?subject=${tryEmail.subject}&body=${tryEmail.body}`">Quick Sign Up!</a>
			</div>
			<div class="imgContainer" :class="{ 'mobileImg': mobile }">
				<img :src="breathingImgSource" alt="Breathing"/>
			</div>
		</article>
		<article :class="{ 'mobileArticle': mobile }">
			<ul :class="{ 'mobileMainPointList': mobile }">
				<li v-for="p in mainPoints" :key="p.id">
					<h3>{{ p.title }}</h3>
					<p>{{ p.details }}</p>
					<router-link class="link" :to="{ hash: `#${p.id}`}">more</router-link>
				</li>
			</ul>
		</article>
	</section>
</template>

<script>

export default {
	name: "HomeSection",
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	components: {},
	data() {
		return {
			mobile: false,
			mainPoints: [
				{id: "breathing", title: "Breathing", details: "Fugiat amet nulla adipisicing culpa laborum aute eiusmod in consectetur."},
				{id: "meditation", title: "Meditation", details: "Consequat officia elit veniam est velit proident consequat sint adipisicing voluptate."},
				{id: "nutrition", title: "Nutrition", details: "Pariatur ipsum pariatur amet aliquip mollit est et nostrud minim ipsum."},
				{id: "workout", title: "Work Out", details: "Aute ea ea anim dolore sit dolore duis commodo veniam mollit enim duis aliqua sunt."},
			],
			tryEmail: {
				email: 'name@domain.com',
				subject: 'Conscious Workout - Tryout Request',
				body: 'Hello,%0D%0AI would like to give Conscious Workout a try.',
			},
		}
	},
	created() {
		window.addEventListener('resize', this.checkMobile)
		this.checkMobile()
	},
	mounted() {},
	computed: {
		breathingImgSource() {
			return require(`@/assets/${this.$options.name}/home_breathing.jpg`);
		},
	},
	methods: {
		LOG(v) {
			console.log(v)
			return v
		},
		checkMobile() {
			this.mobile = window.innerWidth <= 900
		},
	},
}
</script>

<style lang="scss" scoped>
// COLORS
$accent-color: #0396FF;
$bg-color: #cdcdcd;
$dark-text-color: rgb(60, 60, 60);
// FONTS
$paragraph-title-font: "Abril Fatface", cursive;
$paragraph-content-font: "Advent Pro", sans-serif;
.home-section {
	margin-bottom: 50px;
	min-width: 320px;
	display: flex;
	flex-direction: column;

	article {
		margin-bottom: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 0px;
		
		.imgContainer {
			width: 100%;
			margin-top: 0px;
			flex: 2;
			
			img {
				border-radius: 10px 0% 0% 10px;
				width: 100%;
				height: 100%;
			}
		}

		.mobileImg img {
			margin-top: 0px;
			border-radius: 0%;
		}
	
		.text {
			flex: 1;
			width: 60%;
			min-width: 320px;
			margin: 0px 50px;
			color: $dark-text-color;
			padding: 20px 5px 20px 20px;
			display: flex;
			flex-direction: column;
			align-items: left;
			justify-content: space-between;
			
			h2 {
				font-size: 2rem;
				margin: 20px auto;
				font-family: $paragraph-title-font;
			}
			
			p {
				font-size: 18px;
				margin: 20px auto;
			}
			
			a {
				max-width: 145px;
				text-decoration: none;
				color: white;
				background: $accent-color;
				border: 1px solid transparent;
				border-radius: 30px;
				padding: 10px;
				margin-top: 20px;
				transition: .2s ease all;
				text-align: center;

				&:hover {
					background: white;
					border: 1px solid $accent-color;
					color: $accent-color;
				}
			}
		}

		.mobileText {
			align-items: center;
			text-align: center;
			padding: 10px;
		}
		
		ul,
		li {
			list-style: none;
			text-decoration: none;
		}

		ul {
			display: flex;
			align-items: baseline;
			justify-content: space-around;
			padding: 20px;
			margin: auto;
	
			li {
				display: flex;
				flex-direction: column;
				max-width: 150px;
				background: #dedede;
				color: $dark-text-color;
				border-radius: 5px;
				margin: 5px;
				padding: 10px;
				flex: 1;

				h3 {
					font-family: $paragraph-title-font;
					font-size: 1.5rem;
					margin-bottom: 10px;
				}
	
				a {
					color: $dark-text-color;
					margin: auto;
					margin-right: 0px;
				}

				.link {
					font-weight: bold;
				}
			}
		}

		.mobileMainPointList {
			flex-direction: column;

			li {
				max-width: 320px;
			}
		}
	}

	.mobileArticle {
		padding: 0px;
		flex-direction: column-reverse;

	}

}
</style>