<template>
	<header :class="{ 'scrolled-nav': scrolledNav }">
		<nav>
			<div class="branding">
				<router-link class="link" :to="{ hash: '#home'}">
					<img :src="logoImgSource" alt="cwlogo"/>
					<h3>{{ brandName }}</h3>
				</router-link>
			</div>
			<ul v-show="!mobile" class="navigation">
				<li v-for="l in links" :key="l.id">
					<router-link class="link" :to="{ hash: l.link}">{{ l.title }}</router-link>
				</li>
			</ul>
			<div class="icon">
				<i @click="toggleMobileNav" v-show="mobile" class="far fa-bars" :class="{ 'icon-active': mobileNav }"></i>
			</div>
			<transition name="mobile-nav-overlay">
				<div class="overlay" v-show="mobileNav" @click="toggleMobileNav"></div>
			</transition>
			<transition name="mobile-nav">
				<ul v-show="mobileNav" class="dropdown-nav">
					<li class="branding">
						<router-link class="link" :to="{ hash: '#home'}" @click="toggleMobileNav">
							<img :src="logoImgSource" alt="cwlogo"/>
							<h3>{{ brandName }}</h3>
						</router-link>
					</li>
					<li v-for="l in links" :key="l.id">
						<router-link class="link" :to="{ hash: l.link}" @click="toggleMobileNav">{{ l.title }}</router-link>
					</li>
				</ul>
			</transition>
		</nav>
	</header>
</template>

<script>

export default {
	name: 'NavBar',
	components: {},
	props: {
		links: {
			type: Array,
			default: () => [],
		},
		brandName: {
			type: String,
		},
		navHeight: {
			type: Number,
			default: 50,
		},
	},
	data() {
		return {
			scrolledNav: false,
			mobile: false,
			mobileNav: false,
		}
	},
	created() {
		window.addEventListener('resize', this.checkMobile)
		this.checkMobile()
	},
	mounted() {
		window.addEventListener('scroll', this.updateScroll)
		this.updateScroll()
	},
	computed: {
		logoImgSource() {
			return this.scrolledNav
				? require(`@/assets/${this.$options.name}/SVG/cwlogo_black.svg`)
				: require(`@/assets/${this.$options.name}/SVG/cwlogo_blue.svg`)
		},
	},
	methods: {
		LOG(v) {
			console.log(v)
			return v
		},
		toggleMobileNav() {
			this.mobileNav = !this.mobileNav
		},
		checkMobile() {
			this.mobile = window.innerWidth <= 900
			this.mobileNav = !this.mobile
				? this.mobile
				: this.mobileNav
		},
		updateScroll() {
			this.scrolledNav = window.scrollY > 50
		},
	},
}
</script>

<style lang="scss" scoped>
// COLORS
$accent-color: #0396FF;
$bg-color: #cdcdcd;
$dark-text-color: rgb(60, 60, 60);
// FONTS
$paragraph-title-font: "Abril Fatface", cursive;
$paragraph-content-font: "Advent Pro", sans-serif;
header {
	background: $accent-color;
	color: white;
	z-index: 99;
	width: 100%;
	min-width: 300px;
	position: fixed;
	transition: .5s ease all;

	nav {
		position: relative;
		display: flex;
		margin: 0 auto;
		transition: .5s ease all;
		height: v-bind("`${navHeight}px`");
		width: 90%;
		@media (min-width: 1140px) {
			max-width: 1140px;
		}
	}

	ul,
	.link {
		font-weight: 500;
		color: white;
		list-style: none;
		text-decoration: none;
	}

	li {
		text-transform: uppercase;
		padding: 16px 10px;
		border-bottom: 5px solid transparent;

		&:hover {
			border-color: white;
		}
	}

	.link {
		cursor: pointer;
		font-size: 14px;
		transition: .1s ease all;
		padding-bottom: 4px;
	}

	.branding {
		display: flex;
		align-items: center;

		.link {
			display: flex;
			align-items: center;
			
			img {
				height: 50px;
				transition: .5s ease all;
			}
	
			h3 {
				font-family: $paragraph-title-font;
				font-size: 19px;
				text-transform: uppercase;
				word-wrap: break-word;
				width: 130px;
				line-height: 1rem;
			}
		}
	}

	.navigation {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 1;

		li {
			.link {
				font-size: 1rem;
			}
		}
	}

	.icon {
		display: flex;
		align-items: center;
		position: absolute;
		top: 0px;
		right: 20px;
		height: 100%;

		i {
			cursor: pointer;
			font-size: 24px;
			transition: .8s ease all;
		}
	}

	.icon-active {
		transform: rotate(90deg);
	}

	.dropdown-nav {
		background: white;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
		max-width: 250px;
		height: 100%;
		position: fixed;
		top: 0px;
		left: 0px;

		li {
			.link {
				font-size: 1rem;
				padding-right: 20px;
				color: black;
			}
		}
		.branding {
			color: $dark-text-color;

			h3 {
				text-transform: uppercase;
				margin-left: 5px;
			}
		}
	}

	.mobile-nav-enter-active,
	.mobile-nav-leave-active {
		transition: all .25s cubic-bezier(.55, .75, .25, 1);
	}

	.mobile-nav-enter-from,
	.mobile-nav-leave-to {
		transform: translateX(-250px);
	}

	.mobile-nav-enter-to {
		transform: translateX(0px);
	}

	.overlay {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		background: rgba(50, 50, 50, .6);
	}

	.mobile-nav-overlay-enter-active,
	.mobile-nav-overlay-leave-active {
		transition: .25s ease all;
	}

	.mobile-nav-overlay-enter-from,
	.mobile-nav-overlay-leave-to {
		opacity: 0;
	}

	.mobile-nav-overlay-enter-to {
		opacity: 1;
	}
}

.scrolled-nav {
	background: black;

	.branding {
		img {
			height: 50px;
		}
	}
}
</style>