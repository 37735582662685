import { createRouter, createWebHistory } from 'vue-router'
import appConfig from '@/appConfig.json'

const routes = [
	{
		path: '/',
		name: 'HomeSection',
	},
	{
		path: '/',
		name: 'BreathingSection',
	},
	{
		path: '/',
		name: 'MeditationSection',
	},
	{
		path: '/',
		name: 'NutritionSection',
	},
	{
		path: '/',
		name: 'WorkOutSection',
	},
	{
		path: '/',
		name: 'AboutUsSection',
	},
	{
		path: '/',
		name: 'PartnersSection',
	},
]

const router = createRouter({
	history: createWebHistory(),
	mode: 'history',
	routes,
	scrollBehavior (to) {
	if (to.hash) {
		window.scrollTo({
			top: document.querySelector(`${to.hash}`).offsetTop - appConfig.app.navHeight,
			behavior: 'smooth',
		})
	}
	}
})

export default router