<template>
  <v-app>
    <nav-bar
      :id="'navbar'"
      :links="links"
      :brandName="brandName"
      :navHeight="navHeight"
    />
    <v-main class="app-main">
      <home-section
        :id="'home'"
      />
      <hr/>
      <breathing-section
        :id="'breathing'"
      />
      <hr/>
      <meditation-section
        :id="'meditation'"
      />
      <hr/>
      <nutrition-section
        :id="'nutrition'"
      />
      <hr/>
      <work-out-section
        :id="'workout'"
      />
      <hr/>
      <about-us-section
        :id="'aboutus'"
        :collaborators="collaborators"
      />
      <hr/>
      <partners-section
        :id="'partners'"
        :partners="partners"
      />
      <footer-section
        :id="'footer'"
      />
    </v-main>
  </v-app>
</template>

<script>
// CONMPONENTS
import NavBar from '@/components/NavBar'
import HomeSection from '@/components/HomeSection'
import BreathingSection from '@/components/BreathingSection'
import MeditationSection from '@/components/MeditationSection'
import NutritionSection from '@/components/NutritionSection'
import WorkOutSection from '@/components/WorkOutSection'
import AboutUsSection from '@/components/AboutUsSection'
import PartnersSection from '@/components/PartnersSection'
import FooterSection from '@/components/FooterSection'
// CONFIG FILES
import appConfig from '@/appConfig.json';

export default {
  name: 'App',
  components: {
    NavBar,
    HomeSection,
    AboutUsSection,
    BreathingSection,
    MeditationSection,
    NutritionSection,
    WorkOutSection,
    PartnersSection,
    FooterSection,
  },
  data() {
    return {
    brandName: appConfig.app.brandName,
    links: appConfig.app.links,
    collaborators: appConfig.app.collaborators,
    partners: appConfig.app.partners,
    navHeight: appConfig.app.navHeight,
    }
  },
}
</script>

<style lang="scss">

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Actor", sans-serif;
}
html {
  background: #cdcdcd;
}
</style>

<style lang="scss" scoped>
hr {
  width: 75%;
  margin: 50px auto;
  border: none;
  border-top: 1px solid #c5c5c5;
}

.app-main {
  position: relative;
  top: 50px;
}
</style>