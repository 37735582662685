<template>
	<section :id="id" class="about-us-section">
		<ul>
			<li :class="{ 'aboutUsMobile': mobile }">
				<h1>Irure sint nisi nostrud excepteur culpa.</h1>
				<p>Magna exercitation pariatur exercitation magna incididunt nostrud. Aute et excepteur in commodo mollit. Adipisicing eiusmod ullamco cupidatat laboris ad. Consectetur veniam nisi id commodo anim magna. Eu laboris proident enim esse sit magna voluptate aute amet tempor. Voluptate sunt id adipisicing veniam mollit. Sint amet quis laboris eu deserunt id proident excepteur qui labore.</p>
			</li>
			<li>
				<ul class="collaborators">
					<li v-for="c in collaborators" :key="c.id">
						<img :src="collaboratorPhotoImgSource(c.photo)" :alt="c.name">
						<h3>{{ c.name }}</h3>
					</li>
				</ul>
			</li>
		</ul>
	</section>
</template>

<script>

export default {
	name: 'AboutUsSection',
	components: {},
	props: {
		id: {
			type: String,
			default: null,
		},
		collaborators: {
			type: Array,
			default: () => [],
		}
	},
	data() {
		return {
			mobile: false,
		}
	},
	created() {
		window.addEventListener('resize', this.checkMobile)
		this.checkMobile()
	},
	mounted() {},
	computed: {},
	methods: {
		collaboratorPhotoImgSource(photo) {
			return require(`@/assets/${this.$options.name}/${photo}`)
		},
		checkMobile() {
			this.mobile = window.innerWidth <= 900
		},
	},
}
</script>

<style lang="scss" scoped>
// COLORS
$accent-color: #0396FF;
$bg-color: #cdcdcd;
$dark-text-color: rgb(60, 60, 60);
// FONTS
$paragraph-title-font: "Abril Fatface", cursive;
$paragraph-content-font: "Advent Pro", sans-serif;
.about-us-section {
	min-width: 320px;
	padding: 60px 0px;
	
	ul {
		display: flex;
		flex-direction: column;
		color: $dark-text-color;

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 75px;

			h1,
			p {
				width: 33%;
				min-width: 320px;
				max-width: 400px;
				margin: 20px;
			}

			h1 {
				font-size: 2rem;
				font-weight: 400;
				font-family: $paragraph-title-font;
			}
		}
	}

	.aboutUsMobile {
		flex-direction: column;

		h1,
		p {
			text-align: center;
		}
	}

	.collaborators {
		flex-wrap: wrap;
		flex-direction: row;
		align-items: baseline;
		justify-content: space-evenly;
		max-width: 900px;
		overflow-x: auto;

		li {
			flex-grow: 1;
			flex-basis: 30%;
			display: flex;
			flex-direction: column;
			margin: 5px;
			
			img {
				border-radius: 5px;
				margin-bottom: 10px;
			}
		}
	}
}
</style>