import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
// FONTS
import '@fontsource/actor'
import '@fontsource/abhaya-libre'
import '@fontsource/abril-fatface'
import '@fontsource/advent-pro'
import '@fontsource/alegreya'

createApp(App)
  .use(router)
  .mount('#app')
