<template>
	<section :id="id" class="workout-section">
		<article :class="{ 'mobileArticle': mobile }">
			<div class="text" :class="{ 'mobileText': mobile }">
				<h2>Excepteur ut ut ullamco incididunt occaecat.</h2>
				<p>Velit ad in dolor ad esse tempor cillum dolore pariatur excepteur qui occaecat do. Voluptate reprehenderit dolor commodo aliquip in amet. Occaecat deserunt laborum excepteur quis.</p>
				<button @click="expandDetails">{{ moreLessButtonText }}</button>
				<transition name="expanded-details">
					<div v-show="detailsExpanded" class="expanded-details">
						<p>Ea excepteur officia veniam quis consectetur qui non adipisicing. Sint mollit elit eiusmod esse voluptate culpa nulla eiusmod ipsum. Nisi minim qui id in est ea id reprehenderit aliqua elit nisi ex elit in. Ut proident deserunt ea laborum quis. Cupidatat consectetur tempor pariatur magna et quis dolore tempor dolor incididunt sit. Aliquip mollit officia eiusmod amet sint deserunt dolor non voluptate voluptate do eiusmod.</p>
					</div>
				</transition>
			</div>
			<div class="imgContainer" :class="{ 'mobileImg': mobile }">
				<img :src="workoutImgSource" alt="WorkOut"/>
			</div>
		</article>
	</section>
</template>

<script>
export default {
	name: "WorkOutSection",
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			mobile: false,
			detailsExpanded: false,
		}
	},
	created() {
		window.addEventListener('resize', this.checkMobile)
		this.checkMobile()
	},
	computed: {
		workoutImgSource() {
			return require(`@/assets/${this.$options.name}/workout.jpg`)
		},
		moreLessButtonText() {
			return !this.detailsExpanded
			? 'more'
			: 'less'
		},
	},
	methods: {
		LOG(v) {
			console.log(v)
			return v
		},
		checkMobile() {
			this.mobile = window.innerWidth <= 900
		},
		expandDetails() {
			this.detailsExpanded = !this.detailsExpanded
		},
	},
}
</script>

<style lang="scss" scoped>
// COLORS
$accent-color: #0396FF;
$bg-color: #cdcdcd;
$dark-text-color: rgb(60, 60, 60);
// FONTS
$paragraph-title-font: "Abril Fatface", cursive;
$paragraph-content-font: "Advent Pro", sans-serif;
.workout-section {
	margin-bottom: 50px;
	min-width: 320px;
	display: flex;
	flex-direction: column;

	article {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		
		.imgContainer {
			width: 100%;
			flex: 3;
			display: flex;
			justify-content: flex-end;

			@media (max-width: 1400px) {
				flex: 2;
			}
			
			img {
				border-radius: 10px 0px 0px 10px;
				width: 100%;
			}
		}

		.mobileImg {
			justify-content: center;
			
			img {
				border-radius: 0%;
				max-width: 100vw;
				min-width: 320px;
			}
		}
	
		.text {
			flex: 1;
			min-width: 320px;
			color: $dark-text-color;
			padding: 20px 5px 20px 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			@media (min-width: 1200px) {
				padding: 100px;
			}

			@media (min-width: 1500px) {
				padding: 200px;
			}
			
			h2 {
				font-size: 2rem;
				margin: 20px auto;
				font-family: $paragraph-title-font;
			}
			
			p {
				font-size: 18px;
				margin: 20px auto;
			}

			button {
				background: none;
				color: $dark-text-color;
				font-size: 18px;
				font-weight: bold;
				text-decoration: underline;
				border: none;
				width: 50px;
				cursor: pointer;
			}

			.expanded-details {
				overflow: hidden;
				padding: 0px;
			}

			.expanded-details-enter-active,
			.expanded-details-leave-active {
				transition: .25s ease all;
			}

			.expanded-details-enter-from,
			.expanded-details-leave-to {
				max-height: 0px;
			}

			.expanded-details-enter-to,
			.expanded-details-leave-from {
				max-height: 200px;
			}
		}

		.mobileText {
			align-items: center;
			text-align: center;
			max-width: 320px;
		}
		
		.mobileMainPointList {
			flex-direction: column;

			li {
				max-width: 320px;
			}
		}
	}

	.mobileArticle {
		padding: 0px;
		flex-direction: column;
		align-items: center;
	}
}
</style>